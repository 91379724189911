import React from 'react'
import './Allservices.css';
import { Link } from 'react-router-dom';
export default function Allservices() {

  const scrollClickUp = () => {
    window.scrollTo({ top: 0, behavior: 'instant' });
};

  return (
    <div className='services'>
      <div className='containerFull'>
        <div className='servicesContainer'>
          <img src="https://storage.googleapis.com/eletech/twc2.jpeg" alt='Tesla wall connector' />
          <p>
            <h2>Latausratkaisut</h2>
            <br />
            <p>
            Suosittelemme hankkimaan kiinteän latausaseman kotiisi, jos mahdollista. Kiinteä latausasema tarjoaa useita etuja, kuten optimaalisen lataustehon ja akkujen elinkaaren pidentämisen, mikä tekee siitä autovalmistajien ja asiantuntijoiden suositteleman vaihtoehdon.
            </p>
            <Link to="/chargingsolutions">
              <button className="custom-button" onClick={scrollClickUp}>Lue lisää</button>
            </Link>
          </p>
        </div>
        <div className='servicesContainer'>
        <img src='https://storage.googleapis.com/eletech/dr_dirt_hexagon_14grit_in_use_1.jpeg' alt='Hexagon' />
          <p>
            <h2>Hexagon valot</h2>
            <br />
            <p>
            Dr. Dirt Hexagon Garage Sky Gen2
            Suuren suosion saavuttaneiden Hexagon-valojen uudistettu valoelementti on valmistettu korkealaatuisista ja pitkäikäisistä komponenteista. Hexagon-valoelementtien avulla toteutetaan nykyaikaisia, kirkkaita ja miellyttäviä valaistuksia esimerkiksi asuinrakennuksiin, messuille, näyttelyihin, kuntosaleille, liiketiloihin, harrastustiloihin tai autotalleihin.              
            </p>
              <Link to="/light">
              <button className="custom-button" onClick={scrollClickUp}>Lue lisää</button>
            </Link>
          </p>
        </div>
        <div className='servicesContainer'>
          <img src="https://storage.googleapis.com/eletech/sunPane.jpeg" alt='sunpanel' />
          <p>
            <h2>Sähkösaneeraukset</h2>
            <p>
            Teemme sähkösaneerauksia niin vanhoihin kuin uudempiinkin kohteisiin. Sähkösaneerauksella tarkoitetaan sähköjärjestelmän uudistamista ja päivittämistä. Sähkösaneerauksella voidaan parantaa sähköturvallisuutta ja energiatehokkuutta.
            <br />
            <br />
            Tarjoamme laadukkaasti ja ammattitaitoisesti toteutettuja valaistusratkaisuja niin sisä- kuin ulkotiloihin.
            Päivitämme esimerkiksi vanhat valaisimet uusiin energiatehokkaisiin LED-valaisimiin. Näin saat
            valaistuksesi energiatehokkaaksi ja ympäristöystävälliseksi. Hinnoittelemme valaistustyöt tapauskohtaisesti,
            ota yhteyttä niin keskustellaan asiasta lisää!
            </p>
            <br />
             </p>

        </div>
        <div className='servicesContainer'>
        <img src="https://storage.googleapis.com/eletech/industrial.jpg" alt='industrial' />
          <p>
            <h2>Teollisuuden sähkötyöt</h2>
            <br />
            Teemme sähkö- ja automaatioasennuksia ja kunnossapitotöitä teollisuuskohteisiin. Meillä on pitkä kokemus vuosien varrelta teollisuuden sähkötöistä. Tuomme osaamisemme ja ammattitaitomme mielellään käyttöösi.
            <br />
            <br />
            Autamme pitämään tuotantolaitoksen sähkölaitteiston toiminnassa.
            <br />
            <br />
            Tarjoamme automaatiojärjestelmien asennuksia, jotka takaavat tuotantoprosessien tehokkuuden ja virheettömän toiminnan.
            <br />
            <br />
            Pyrimme paikallistamaan sähköviat nopeasti ja tehokkaasti, minimoiden seisokkiajan ja ehkäisten tuotannon häiriöitä.
            <br />
            <br />
            Säännöllinen ja ennakoiva kunnossapito on avainasemassa toimintavarmuuden ylläpitämisessä.
            Tarjoamme kattavaa sähkölaitteistojen kunnossapitoa, jonka avulla voimme ennakoida mahdollisia ongelmia ja pidentää sähköjärjestelmienne käyttöikää.
          </p>
        </div>
      </div>
    </div>
  )
}
